.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-cell {
    border: 1px solid rgba(224, 224, 224, 1);
}

.MuiDataGrid-toolbarContainer.css-ypofq1-MuiDataGrid-toolbarContainer {
    background: white;
    border-bottom: solid 3px #f0f2f4;
}


.MuiDataGrid-main .MuiDataGrid-columnHeaders .Editable-NotRequired { 
    background-color:hsl(60, 100%, 97%);  /* yellow */
}
.MuiDataGrid-main .MuiDataGrid-columnHeaders .Editable-Required { 
    background-color:hsl(0, 100%, 97%);  /* pink */
}
.MuiDataGrid-main .MuiDataGrid-cell--editable {
    color:hsl(220, 100%, 20%);  /* blue */
}

/********************************************************************************
    Client Funding Year report
********************************************************************************/
/* .MuiDataGrid-main .MuiDataGrid-row.ExpectedNumberOfForm470s-true { 
} */
.MuiDataGrid-main .MuiDataGrid-cell.ExpectedNumberOfForm470s-false { 
    background-color:hsl(40, 75%, 95%) !important;
}
.MuiDataGrid-main .MuiDataGrid-cell.ExpectedNumberOfForm471s-false { 
  background-color:hsl(40, 75%, 95%) !important;
}


/* 
Client Not Contacted      Orange
# of expected = certified     Green
# of expected < draft+certified     Yellow
# of expected >= draft+certified    Red 
.MuiDataGrid-main .MuiDataGrid-row .ClientNotContacted             { background-color:hsl(40, 75%, 95%); }
.MuiDataGrid-main .MuiDataGrid-row .NumOfExpectedEqualsCertified   { background-color:hsl(150, 75%, 95%); }
.MuiDataGrid-main .MuiDataGrid-row .NumOfExpectedLTDraftCertified  { background-color:hsl(60, 75%, 95%); }           
.MuiDataGrid-main .MuiDataGrid-row .NumOfExpectedGTDraftCertified  { background-color:hsl(0, 75%, 95%); }
*/


/********************************************************************************
    Applicants report
********************************************************************************/
.MuiDataGrid-main .MuiDataGrid-row.NotFiling470-false,
.MuiDataGrid-main .MuiDataGrid-row.NotFiling471-false { 
    background-color: #dff0d8 !important; 
}

/********************************************************************************
    Form 471 Tracker Applications report
********************************************************************************/
.MuiDataGrid-main .MuiDataGrid-row.Form470-Certified, 
.MuiDataGrid-main .MuiDataGrid-row.Form471-Certified, 
.MuiDataGrid-main .MuiDataGrid-row.Form471-Committed { 
    background-color: #dff0d8 !important; 
} 

.MuiDataGrid-main a.ExternalLink:link { text-decoration: none; border-bottom: 1px dashed #034af3; }
.MuiDataGrid-main a.ExternalLink:hover { border-bottom: 0 none; }

/* for testing: .MuiDataGrid-main .ApplicationOwner-Default { background-color:hsl(200, 50%, 50%); } */
.MuiDataGrid-main .ApplicationOwner-Overridden,
.MuiDataGrid-main .TaskOwner {
    color:hsl(190, 100%, 30%); /* teal */ 
}

.MuiDataGrid-main .AppStatus-Incomplete   { background-color:#FFF0F0; }
.MuiDataGrid-main .AppStatus-Complete     { background-color:#FFFFF0; }
.MuiDataGrid-main .AppStatusCombined .ReviewStatus { font-family:'Times New Roman', Times, serif; font-size:0.9em; }

/*.MuiDataGrid-main .QAReviewStatus-QAReady             {  }*/
.MuiDataGrid-main .QAReviewStatus-QAInProgress        { background-color:hsl(60, 75%, 95%); }
.MuiDataGrid-main .QAReviewStatus-QAReturned          { background-color:hsl(0, 75%, 95%); }
.MuiDataGrid-main .QAReviewStatus-QAPassed            { background-color:hsl(150, 75%, 95%); }
.MuiDataGrid-main .QAReviewStatus-WillNotBeReviewed   { background-color:hsl(150, 75%, 95%); }
/********************************************************************************/


/*.twolines {
  line-height: normal !important;
}*/
